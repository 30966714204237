import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AllRolesResponse,
  CreateRoleRequest,
  PermissionResponse,
  SingleRoleResponse,
} from '@core/models/admin/roles-and-permissions';
import { Pagination } from '@core/models/pagination.model';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RolesAndPermissionsService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  BASE_URL = environment.ADMIN_BASE_URL + '/v1/role';

  getAllPermissions(): Observable<PermissionResponse> {
    return this.http.get<PermissionResponse>(`${this.BASE_URL}/get-permission`);
  }

  filterAllRoles(queryParams: string): Observable<AllRolesResponse> {
    return this.http.get<AllRolesResponse>(`${this.BASE_URL}/filter-roles?${queryParams}`);
  }
  getAllRoles(
    pagination: Pagination = new Pagination()
  ): Observable<AllRolesResponse> {
    const uniqueStateUrl = 'get-all-roles';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<AllRolesResponse>(
        `${this.BASE_URL}/get-roles?take=${pagination.size}&skip=${pagination.skip}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getRoleById(roleId: string): Observable<SingleRoleResponse> {
    return this.http.get<SingleRoleResponse>(
      `${this.BASE_URL}/get-role-by-id?id=${roleId}`
    );
  }

  updateRoleName(updatePayload: {
    name: string;
    role_id: string;
  }): Observable<unknown> {
    return this.http.post(`${this.BASE_URL}/update-roles`, updatePayload);
  }

  addPermissionToRole(payload: { permission_id: string; role_id: string }) {
    return this.http.post(`${this.BASE_URL}/add-permission-to-role`, payload);
  }

  removePermissionFromRole(permissionId: string) {
    return this.http.post(
      `${this.BASE_URL}/remove-permission-from-role?id=${permissionId}`,
      {}
    );
  }

  createNewRole(payload: CreateRoleRequest) {
    return this.http.post(`${this.BASE_URL}/create-role`, payload);
  }

  deleteRole(roleId: string) {
    return this.http.delete(`${this.BASE_URL}/delete-role?id=${roleId}`);
  }
}
